/**
 * @generated SignedSource<<0a59588a1318c0faadc95c2d3c5178cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavItem_navItem$data = {
  readonly dataTn: string | null;
  readonly eventName: string | null;
  readonly exactLocation: boolean | null;
  readonly href: string | null;
  readonly interactionType: string | null;
  readonly items?: ReadonlyArray<{
    readonly eventName: string | null;
    readonly interactionType: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"NavDropdownItem_navItem">;
  } | null> | null;
  readonly newWindow: boolean | null;
  readonly title: string | null;
  readonly type: string | null;
  readonly " $fragmentType": "NavItem_navItem";
};
export type NavItem_navItem$key = {
  readonly " $data"?: NavItem_navItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavItem_navItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interactionType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavItem_navItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "href",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataTn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newWindow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exactLocation",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NavBarListItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NavDropdownItem_navItem"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "NavBarList",
      "abstractKey": null
    }
  ],
  "type": "NavBarElement",
  "abstractKey": "__isNavBarElement"
};
})();

(node as any).hash = "c17648fb25d3a893e58477c04b4408a8";

export default node;
