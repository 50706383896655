/**
 * @generated SignedSource<<633c4197ec26c317c77e557c1551fef8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DalAccountNav_accountNav$data = ReadonlyArray<{
  readonly eventName: string | null;
  readonly interactionType: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"NavDropdownItem_navItem" | "NavItem_navItem">;
  readonly " $fragmentType": "DalAccountNav_accountNav";
}>;
export type DalAccountNav_accountNav$key = ReadonlyArray<{
  readonly " $data"?: DalAccountNav_accountNav$data;
  readonly " $fragmentSpreads": FragmentRefs<"DalAccountNav_accountNav">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DalAccountNav_accountNav",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interactionType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavItem_navItem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavDropdownItem_navItem"
    }
  ],
  "type": "NavBarElement",
  "abstractKey": "__isNavBarElement"
};

(node as any).hash = "a7178fb241c2c5519e169bb33fa13a1f";

export default node;
