import { trackEvent, eventNameConstants } from 'dibs-tracking';

type TTrackNavProps = Partial<{
    action: string | null;
    label: string | null;
    interactionType?: string | null;
    isImpression?: boolean;
}>;

type TPrepareTrackEvent = (label: string) => void;

const category = 'header nav';
const event = 'e_ga_event';

const TRACK_ACTIONS = {
    MY_ACCOUNT: 'my account',
    SUPPORT: 'support',
};

const trackNavInteraction = ({
    action,
    label,
    interactionType,
    isImpression = false,
}: TTrackNavProps): void => {
    if (action) {
        action = `${action.toLowerCase()} ${isImpression ? 'displayed' : 'clicked'}`;
        label = (label || '').toLocaleLowerCase();
        interactionType = (interactionType || '').toLocaleLowerCase();
        trackEvent({
            eventName: eventNameConstants.EVENT_NAVIGATION,
            interaction_type: interactionType,
            step_interaction_name: isImpression ? 'impression' : 'click',
            trigger: action,
            event,
            category,
            action,
            label,
        });
    }
};

const prepareTrackEvent =
    (
        action: TTrackNavProps['action'],
        interactionType?: TTrackNavProps['interactionType']
    ): TPrepareTrackEvent =>
    (label: TTrackNavProps['label']) =>
        trackNavInteraction({ action, label, interactionType });

export { trackNavInteraction, prepareTrackEvent, TRACK_ACTIONS, type TPrepareTrackEvent };
