import { localStorage } from 'dibs-browser-storage';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';

export const RECOMMENDATIONS_PAGE_VIEW_DATE = 'RECOMMENDATIONS_PAGE_VIEW_DATE';

export const hasRecommendationSnoozeExpired = (sellerId: string): boolean => {
    const snoozeExpiration = localStorage.getItem(RECOMMENDATIONS_PAGE_VIEW_DATE);
    if (!snoozeExpiration || !hasKey(snoozeExpiration, sellerId)) {
        return true;
    }
    const expirationTime: number = snoozeExpiration?.[sellerId];
    return new Date() > new Date(expirationTime);
};

export const getRecommendationSnoozeTime = (sellerId: string): number | undefined => {
    const snoozeExpiration = localStorage.getItem(RECOMMENDATIONS_PAGE_VIEW_DATE);
    if (snoozeExpiration && hasKey(snoozeExpiration, sellerId)) {
        return snoozeExpiration?.[sellerId];
    }
    return undefined;
};
