import { FC, Fragment, MouseEvent, ReactNode } from 'react';
import classnames from 'classnames';

import dibsCss from 'dibs-css';
import { FormattedMessage } from 'dibs-react-intl';
import { trackNavInteraction } from '../../helpers/tracking';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';

import navItemStyles from './styles/NavItem.scss';
import styles from './styles/CreateListingTooltip.scss';

const MskuListing = 'msku-listing';

type ListingType = {
    readonly text: ReactNode | null;
    readonly dataTn: string | null;
    readonly url: string | null;
    readonly eventName?: string | null;
};

type Props = {
    questionCopy?: ReactNode;
    listingTypes: readonly (ListingType | null)[];
    openBulkUploadModal: () => void;
    isMobile?: boolean;
};

const defaultQuestionCopy = (
    <FormattedMessage
        id="dal.navItems.createListingToooltip.question"
        defaultMessage="What type of listing do you want to create?"
    />
);
const defaultListingTypes: ListingType[] = [
    {
        text: (
            <FormattedMessage
                id="dal.navItems.createListingToooltip.regular"
                defaultMessage="Regular Listing"
            />
        ),
        url: '/dealers/item',
        dataTn: 'regular-listing',
    },
    {
        text: (
            <FormattedMessage
                id="dal.navItems.createListingToooltip.multiSku"
                defaultMessage="MultiSku Listing"
            />
        ),
        url: '/dealers/multi-sku-item',
        dataTn: 'msku-listing',
    },
];

export const CreateListingTooltipItems: FC<Props> = ({
    questionCopy = defaultQuestionCopy,
    listingTypes = defaultListingTypes,
    openBulkUploadModal,
    isMobile,
}) => {
    const handler = (e: MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        openBulkUploadModal();
    };

    return (
        <>
            {listingTypes.some(type => type?.dataTn === MskuListing) && (
                <li
                    className={classnames(navItemStyles.dropdownItem, styles.question)}
                    key="tooltip-question-copy"
                >
                    {questionCopy}
                </li>
            )}
            {listingTypes.map(type => {
                if (!type) {
                    return null;
                }
                const isBulkUploadOption = type.dataTn === 'bulk-upload';
                const isMskuUploadOption = type.dataTn === MskuListing;
                return (
                    <Fragment key={type.dataTn}>
                        <li
                            key={`item-${type.dataTn}`}
                            data-tn={type.dataTn}
                            className={classnames(navItemStyles.dropdownItem, {
                                [dibsCss.mb0]: isMobile,
                            })}
                        >
                            <a
                                href={type.url || undefined}
                                onClick={e => {
                                    if (isBulkUploadOption) {
                                        handler(e);
                                    }

                                    if (type.eventName) {
                                        trackNavInteraction({
                                            action: 'create listing',
                                            label: type.eventName,
                                        });
                                    }
                                }}
                            >
                                <span>{type.text}</span>
                                <ArrowRight className={styles.arrowIcon} />
                            </a>
                        </li>
                        {isMskuUploadOption && (
                            <li
                                key={`divider-${type.dataTn}`}
                                className={navItemStyles.dropdownDivider}
                            />
                        )}
                    </Fragment>
                );
            })}
        </>
    );
};
