import PropTypes from 'prop-types';
import styles from './styles/Badge.scss';
import classNames from 'classnames';
import { datadogRum } from '@datadog/browser-rum';
import {
    trackEvent,
    eventNameConstants,
    stepInteractionConstants,
    interactionTypeConstants,
} from 'dibs-tracking';
import { useEffect } from 'react';

const paddingPropToStyle = padding =>
    padding
        ? Object.keys(padding).reduce((acc, curr) => {
              const caps = curr.charAt(0).toLocaleUpperCase() + curr.slice(1);
              acc[`padding${caps}`] = padding[curr] + 'px';
              return acc;
          }, {})
        : null;

export const Badge = ({ content, dataTn, padding }) => {
    useEffect(() => {
        trackEvent({
            eventName: eventNameConstants.EVENT_NAVIGATION,
            step_interaction_name: stepInteractionConstants.STEP_INTERACTION_IMPRESSION,
            interaction_type: interactionTypeConstants.INTERACTION_TYPE_RED_DOT,
            trigger: dataTn,
        });
    }, [dataTn]);
    datadogRum.addAction(`${dataTn}-Badge-Displayed`);
    return (
        <span
            className={classNames(styles.notificationBadge, { [styles.emptyBadge]: !content })}
            style={paddingPropToStyle(padding)}
            data-tn={dataTn}
        >
            {content}
        </span>
    );
};

Badge.propTypes = {
    content: PropTypes.node,
    dataTn: PropTypes.string,
    padding: PropTypes.shape({
        top: PropTypes.number,
        right: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
    }),
};
