/**
 * @generated SignedSource<<1b71293f76d286ba85c5c1318047579b>>
 * @relayHash 567ec305b908829c9178a7bdb98a24e5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-admin-accounts/494.0.0-2025-01-13T17:53:04.625Z/internalLoginMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SupportedMFATypes = "CODE" | "%future added value";
export type UserLoginInput = {
  authHash: string;
  captchaKey?: string | null;
  captchaToken?: string | null;
  clientMutationId?: string | null;
  cookieDomain?: string | null;
  countryCode?: string | null;
  email?: string | null;
  emailLoginToken?: string | null;
  marketingOptIn?: boolean | null;
  mfaVerificationCode?: string | null;
  password?: string | null;
  registrationTrigger?: string | null;
  socialClientId?: string | null;
  socialNetworkName?: string | null;
  socialToken?: string | null;
  socialUserEmail?: string | null;
  socialUserFirstName?: string | null;
  socialUserLastName?: string | null;
  supportedMFATypes?: ReadonlyArray<SupportedMFATypes | null> | null;
};
export type internalLoginMutation$variables = {
  input: UserLoginInput;
};
export type internalLoginMutation$data = {
  readonly userLogin: {
    readonly __typename: "UserLoginPayload";
  } | null;
};
export type internalLoginMutation = {
  response: internalLoginMutation$data;
  variables: internalLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserLoginPayload",
    "kind": "LinkedField",
    "name": "userLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "internalLoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "internalLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "app-admin-accounts/494.0.0-2025-01-13T17:53:04.625Z/internalLoginMutation",
    "metadata": {},
    "name": "internalLoginMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c81f27a594a91efa2d6e64814f52f12f";

export default node;
